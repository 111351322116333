<template>
  <base-section
    id="Survey"
    space="0"
  >
    <v-container
      fluid
      class="py-0"
    >
      <v-row justify="center">
        <v-col
          class="d-flex justify-center align-center"
          :class="isMobile ? 'pt-7 px-4 pb-10' : 'py-15'"
          cols="12"
          md="6"
        >
          <v-responsive>
            <base-info-card
              mobile-size="text-h5"
              :subtitle="$t('zcp.subtitle')"
              :title="$t('zcp.title3')"
              color="grey lighten-4"
            />

            <base-body>
              {{ $t('zcp.content7') }}
            </base-body>

            <a
              :href="$i18n.locale == 'zh' ? 'https://wj.qq.com/s2/9242377/b6d7/' : 'https://www.surveymonkey.com/r/ZCPN001'"
              target="_black"
            >
              <base-btn>
                <v-icon class="mr-1">
                  {{ icon }}
                </v-icon>
                {{ $t('zcp.btn1') }}
              </base-btn>
            </a>
          </v-responsive>
        </v-col>

        <v-col
          v-if="!isMobile"
          class="d-flex justify-center align-center"
          cols="12"
          md="3"
        >
          <base-img
            :src="require('@/assets/zcp/survey.png')"
            class="mx-auto"
            data-aos="zoom-in"
            contain
            max-width="70%"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  import { mdiNoteEditOutline } from '@mdi/js'
  export default {
    name: 'Survey',
    data: () => ({
      icon: mdiNoteEditOutline,
    }),
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.width <= 768
      },
    },
  }
</script>
